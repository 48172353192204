import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

// Render your React component instead
const selector = "cares-apps";
const domNode = document.getElementById(selector);

if (domNode) {
	console.log(`React app rendered for id selector: ${selector}`);
	const root = createRoot(domNode);
	root.render(<App />);
}
