import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class IngredientModal extends Component {
		constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.handleModalPopup();
	}

	handleModalPopup(): void {
		const modalTriggerBtn = this._element.querySelector(
		"[data-element='ingredient-modal-trigger']"
	);
		if (modalTriggerBtn) {
			$(modalTriggerBtn).magnificPopup({
				items: {
					src: `#ingredient-modal`,
					type: "inline"
				},
				mainClass: ""
			});
		}
	}
}

ComponentFactory.registerComponent("ingredientModal", IngredientModal);
