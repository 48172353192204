import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";

export class IngredientToggle extends Component {
		constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
			this.scrollToIngredients();
		}

		scrollToIngredients(): void {
			const hash = window.location.hash;

			if (hash === "#ingredients") {
					const targetElement = document.getElementById("pdp-ingredientstatement");

					if (targetElement) {
						const observer = new IntersectionObserver((entries, observer) => {
							if (entries[0].isIntersecting) {
								this.pdpAccordion();
								observer.disconnect();
							}
						});

						observer.observe(targetElement);
						targetElement.scrollIntoView({ behavior: "smooth", block: "start" });

					} else {
							console.error("Element with ID 'pdp-ingredientstatement' not found.");
					}
			}
		}

		pdpAccordion(): void {
			document.querySelectorAll("#pdp-ingredients-accord-heading").forEach((heading) => {
				const nextElement = heading.nextElementSibling as HTMLElement | null;

				if (nextElement) {
					const isVisible = nextElement.style.display === "block";
					nextElement.style.display = isVisible ? "none" : "block";
					nextElement.style.transition = "height 300ms ease";
				}

				if (heading.classList.contains("pdp-accord--open")) {
					heading.setAttribute("aria-expanded", "false");
				} else {
					heading.setAttribute("aria-expanded", "true");
				}

				heading.classList.toggle("pdp-accord--open");
			});
		}
}

ComponentFactory.registerComponent("ingredientToggle", IngredientToggle);
