import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

export class DaypartHero extends Component {
	evtTypes: { selectPromotion: string; viewPromotion: string };
	constructor(element: HTMLElement, app: ICfa) {
		super(element, app);
		this.evtTypes = {
			selectPromotion: "select_promotion",
			viewPromotion: "view_promotion"
		};

		this.pushToDataLayer(null, this.evtTypes.viewPromotion);
		this.pushHomepageLoad();
		this.applyDataPushOnClick();
	}

	pushHomepageLoad(): void {
		const data = {
			method: "homepage",
			module_name: "hero",
			daypart: this.getFieldText("daypart"),
			creative_title: this.getFieldText("creativetitle")
		};

		if(!data["daypart"] || !data["creative_title"]) return

		DataLayerService.pushDataLayerEvent_DaypartHomepageLoaded(data);
	}

	pushToDataLayer(target = null, event: string): void {
		const { daypartVariation } = this._element.dataset || "";
		const headerText = this.getHeaderText();
		const imgSrc = this.getImgSrc();
		const ctas = this.getCtaText();

		let data = {
			event,
			ecommerce: {
				creative_name: daypartVariation,
				creative_slot: ctas,
				promotion_id: headerText,
				promotion_name: `${imgSrc}, ${daypartVariation}`
			}
		};

		if (event === this.evtTypes.selectPromotion) {
			const btnLabel = target?.getAttribute("aria-label");
			if (!btnLabel) {
				console.warn(
					`No aria label provided to target: ${target}. Value must be provided for dataLayer.click_type`
				);
			}

			data = {
				...data,
				click_type: btnLabel || null
			};

			DataLayerService.pushDataLayerEvent_Promotion(event, {
				index: data.ecommerce.promotion_id,
				items: [{ item_name: data.ecommerce.creative_name }],
				item_list_name: data.ecommerce.promotion_name,
				list_type: data.ecommerce.creative_slot,
				search_type: btnLabel
			});
		}
		(dataLayer || []).push(data);

		if (event === this.evtTypes.viewPromotion) {
			DataLayerService.pushDataLayerEvent_Promotion(event, {
				item_id: data.ecommerce.promotion_id,
				items: [{ item_name: data.ecommerce.creative_name }],
				item_list_name: data.ecommerce.promotion_name,
				list_type: data.ecommerce.creative_slot
			});
		}
	}

	applyDataPushOnClick(): void {
		const ctas = this.getCtas();
		if (!ctas) {
			return;
		}

		ctas.forEach(btn =>
			btn.addEventListener("click", e =>
				this.pushToDataLayer(e.target, this.evtTypes.selectPromotion)
			)
		);
	}

	getHeaderText(): string | null {
		const selector = ".wrapper h1";
		const headerText = this._element.querySelector(selector);

		if (!headerText) {
			console.warn(`No header text element found with selector: ${selector}`);

			return null;
		}

		return headerText.innerHTML;
	}

	getImgSrc(): string | null {
		const imgContainer = this._element.querySelector(".image-container");
		const isMobileView = window.innerWidth < this._app.layout.desktopWidth;
		const classLookup = isMobileView ? ".mobile-img" : ".desktop-img";

		if (!imgContainer) {
			return null;
		}

		const src = imgContainer.querySelector(classLookup)?.src;

		return src;
	}

	getCtaText(): string | null {
		const links = this.getCtas();
		if (!links) {
			return null;
		}
		const stringVal = links
			.map(i => i.getAttribute("aria-label"))
			.filter(i => i !== "")
			.join(",");

		return stringVal;
	}

	getCtas(): Array<Element> | null {
		const btnContainer = this._element.querySelector(".btn-container");
		const aLinks = btnContainer?.querySelectorAll("a");
		const bLinks = btnContainer?.querySelectorAll("button");

		return [...aLinks, ...bLinks];
	}

	getFieldText(attrName: string): string | null | undefined {
		const selector = `[data-${attrName}]`;

		if(!document.querySelector(selector)) return

		return document.querySelector(selector)?.getAttribute(`data-${attrName}`);
	}

	getImgContainer(): Element | null {
		const imgContainer = this._element.querySelector(".image-container");

		if (!imgContainer) {
			return null;
		}

		return imgContainer;
	}
}

ComponentFactory.registerComponent("daypartHero", DaypartHero);
