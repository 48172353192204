import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { DataLayerService } from "../../services/DataLayerService";

interface IGeoLocationError {
	code: number;
	message: string;
}

export class GeoLocation extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);

		this.handleUseLocationBtn();
	}

	handleUseLocationBtn(): void {
		const sourceContainer = document.querySelector<HTMLElement>("#ingredient-modal");

		this._element.addEventListener("click", e => {
			e.preventDefault();
			if (navigator.geolocation) {
				const geoOptions = { timeout: 5000 };

				navigator.geolocation.getCurrentPosition(
					position => {
						this.addPositionCoordsToForm(position.coords);

						const menuGuid = sourceContainer?.querySelector<HTMLInputElement>("#menuItemGuid")?.value;

						if(menuGuid){
							const forms = document.querySelectorAll<HTMLFormElement>(".use-location-form");

							forms.forEach((form) => {
								const menuItemField = form.querySelector<HTMLInputElement>("#menuItem");

								if(menuItemField) {
									menuItemField.value = menuGuid;
								}
							});
						}

						this.submitForm();

						// CFAC-34725 Fire whenever a user successfully completes a form
						DataLayerService.pushDataLayerEvent_Forms("form_complete", {
							identifier: this.getlayerIdentifier(),
							name: this.getlayerName(),
							type: "UseMyLocationForm"
						});
					},
					error => this.geoLocationError(error),
					geoOptions
				);
			} else {
				alert("The Browser does not support Geolocation");

				// CFAC-34725 Fire whenever a user unsuccessfully completes a form
				DataLayerService.pushDataLayerEvent_Forms("form_error", {
					error_category: "UseMyLocationFormSubmission",
					error_message: "The Browser does not support Geolocation",
					identifier: this.getlayerIdentifier(),
					name: this.getlayerName(),
					type: "UseMyLocationForm"
				});
			}
		});
	}

	private addPositionCoordsToForm({
		latitude,
		longitude
	}: {
		latitude: number;
		longitude: number;
	}): void {
		if (this.latitudeInput) {
			this.latitudeInput.value = JSON.stringify(latitude);
		}

		if (this.longitudeInput) {
			this.longitudeInput.value = JSON.stringify(longitude);
		}
	}

	private submitForm(): void {
		const form = this._element as HTMLFormElement;

		form.submit();
	}

	private get latitudeInput(): HTMLInputElement | null {
		return this._element.querySelector("#loclatitude");
	}

	private get longitudeInput(): HTMLInputElement | null {
		return this._element.querySelector("#loclongitude");
	}

	private geoLocationError(error: IGeoLocationError): void {
		const permissionErrorCode = 1;
		const timeoutErrorCode = 3;

		if (error.code === permissionErrorCode) {
			alert(
				"Your browser does not have permission to the geolocator. Enable service in your permissions."
			);
		}
		if (error.code === timeoutErrorCode) {
			alert("Geolocator service timed out");
		}

		// CFAC-34725 Fire whenever a user unsuccessfully completes a form
		DataLayerService.pushDataLayerEvent_Forms("form_error", {
			error_category: "UseMyLocationFormSubmission",
			error_message: `Received error type: ${error.code}, ${error.message}`,
			identifier: this.getlayerIdentifier(),
			name: this.getlayerName(),
			type: "UseMyLocationForm"
		});

		throw new Error(`Received error type: ${error.code}, ${error.message}`);
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent("geoLocation", GeoLocation);
