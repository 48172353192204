import { Component } from "../../../components/base/Component";
import { ICfa } from "../../../models/ICfa";
import { ComponentFactory } from "../../../services/ComponentFactory";
import { isUserLoggedIn } from "../../../util/User";
import { DataLayerService } from "../../services/DataLayerService";

enum LocationToolsState {
	unknown,
	signedInFavoritedRestaurants,
	signedInNoFavoritedRestaurants,
	signedOut
}

enum ModalIdsToDisplay {
	default = "",
	findRestaurantsModal = "find-restaurants-modal",
	favoritedRestaurants = "favorited-restaurants-yext"
}
export class LocationTools extends Component {
	private modalIdToDisplay: ModalIdsToDisplay = ModalIdsToDisplay.default;
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
		this.handleModalPopup();
	}

	handleModalPopup(): void {
		this.handleState();
		this.setButtonModalLink();
	}

	private handleState(): void {
		if (isUserLoggedIn()) {
			this.state = this.hasFavoritedRestaurants
				? LocationToolsState.signedInFavoritedRestaurants
				: LocationToolsState.signedInNoFavoritedRestaurants;
		} else {
			this.state = LocationToolsState.signedOut;
		}
	}

	private set state(value: LocationToolsState) {
		this._element.classList.remove(
			"signedIn",
			"signedOut",
			"hasFavoritedRestaurants",
			"noFavoritedRestaurants"
		);
		switch (value) {
			case LocationToolsState.signedInFavoritedRestaurants:
				this._element.classList.add("signedIn", "hasFavoritedRestaurants");
				this.modalIdToDisplay = ModalIdsToDisplay.favoritedRestaurants;
				break;

			case LocationToolsState.signedInNoFavoritedRestaurants:
				this._element.classList.add("signedIn", "noFavoritedRestaurants");
				this.modalIdToDisplay = ModalIdsToDisplay.findRestaurantsModal;
				break;

			case LocationToolsState.signedOut:
				this._element.classList.add("signedOut", "noFavoritedRestaurants");
				this.modalIdToDisplay = ModalIdsToDisplay.findRestaurantsModal;
				break;

			case LocationToolsState.unknown:
				this.modalIdToDisplay = ModalIdsToDisplay.findRestaurantsModal;
				break;

			default:
				throw new Error(`Invalid state: ${value}; current: ${this.state}`);
		}
	}

	private setButtonModalLink(): void {
		const findRestaurantBtn = this._element.querySelector(
			"[data-element='findRestaurant']"
		);

		const sourceContainer = document.querySelector<HTMLElement>("#ingredient-modal");

		findRestaurantBtn?.addEventListener("click", async e => {
			e.preventDefault();

			const menuGuid = sourceContainer?.querySelector<HTMLInputElement>("#menuItemGuid")?.value;

			if(menuGuid){
				const forms = document.querySelectorAll<HTMLFormElement>(".pwo-modal-search");

				forms.forEach((form) => {
					const menuItemField = form.querySelector<HTMLInputElement>("#menuItem");

					if(menuItemField) {
						menuItemField.value = menuGuid;
					}
				});
			}

			// CFAC-34725 Fire whenever a user is presented with a form on a page.
			DataLayerService.pushDataLayerEvent_Forms("form_view", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "PWOSearchForm"
			});

			// CFAC-34725 Fire whenever a user is presented with a form on a page.
			DataLayerService.pushDataLayerEvent_Forms("form_view", {
				identifier: this.getlayerIdentifier(),
				name: this.getlayerName(),
				type: "UseMyLocationForm"
			});
		});

		if (findRestaurantBtn) {
			$(findRestaurantBtn).magnificPopup({
				items: {
					src: `#${this.modalIdToDisplay}`,
					type: "inline"
				},
				mainClass: "remove-use-location"
			});
		}
	}

	// get favoritedRestaurantsState
	get hasFavoritedRestaurants(): boolean {
		return this._element.dataset.hasFavoritedRestaurants === "true";
	}

	getlayerIdentifier(): string {
		return this._element.dataset.layerIdentifier || "";
	}

	getlayerName(): string {
		return this._element.dataset.layerName || "";
	}
}

ComponentFactory.registerComponent("locationTools", LocationTools);
