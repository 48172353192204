import { Component } from "../../components/base/Component";
import { ICfa } from "../../models/ICfa";
import { ComponentFactory } from "../../services/ComponentFactory";

/*
CFAC-34719 2023 Data Layer
https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events
*/ export class DataLayerService extends Component {
	constructor(_element: HTMLElement, _app: ICfa) {
		super(_element, _app);
	}

	// Flexible method that doesn't require defining the eventModel object.
	public static pushDataLayerEvent(event: string, eventModel: Object): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({ event: event, eventModel: eventModel });
	}

	/*
	CFAC-34720 Accordion
	https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events/accordion
	*/
	public static pushDataLayerEvent_Accordion(toggle: Element): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: toggle.getAttribute("data-layer-event"),
			eventModel: {
				identifier: toggle.getAttribute("data-layer-identifier"),
				name: toggle.getAttribute("data-layer-name"),
				heading: toggle.getAttribute("data-layer-heading"),
				index: toggle.getAttribute("data-layer-index"),
				type: toggle.getAttribute("data-layer-type")
			}
		});
	}

	/*
	CFAC-34723 Carousel
	https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events/carousel
	*/
	public static pushDataLayerEvent_Carousel(
		sliderEl: JQuery,
		event: string,
		index: number,
		targetIndex: number | undefined,
		defaultName: string
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: event,
			eventModel: {
				eventModel: {
					identifier: sliderEl.attr("data-layer-identifier") || defaultName,
					name: sliderEl.attr("data-layer-name") || defaultName,
					index: index,
					target_index: targetIndex
				}
			}
		});
	}

	/*
	CFAC-34725 Forms
	https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events/forms
	*/
	public static pushDataLayerEvent_Forms(
		event: string,
		eventModel: object
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: event,
			eventModel: eventModel
		});
	}

	public static pushDataLayerEvent_Menu_CtaClick(
		cta: Element,
		moduleName: string
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		const eventModel = {
			module_name: moduleName,
			link_category: "Food menu",
			link_text: cta.innerHTML,
			link_url: cta.href
		};
		window.dataLayer.push({
			event: "cta_click",
			eventModel
		});
	}

	public static pushDataLayerEvent_Menu_ContentClick(cta: Element): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object

		const eventModel = {
			module_name: cta.dataset.layerListName,
			link_category: cta.dataset.layerCategory,
			link_text: cta.ariaLabel,
			link_url: cta.href,
			method: cta.querySelector("img") ? "Image Link" : "Text Link"
		};

		window.dataLayer.push({
			event: "content_click",
			eventModel
		});
	}

	/*
	CFAC-34726 Lists
	https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events/lists
	*/
	public static pushDataLayerEvent_Lists_SelectContent(toggle: Element): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "select_content",
			eventModel: {
				content_type: toggle.getAttribute("data-layer-content-type"),
				identifier: toggle.getAttribute("data-layer-identifier"),
				index: toggle.getAttribute("data-layer-index"),
				list_type: toggle.getAttribute("data-layer-list-type"),
				name: toggle.getAttribute("data-layer-name")
			}
		});
	}

	public static pushDataLayerEvent_Lists_SelectItem(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "select_item",
			ecommerce: {
				item_list_id: toggle.getAttribute("data-layer-list-id"),
				item_list_name: toggle.getAttribute("data-layer-list-name"),
				list_type: toggle.getAttribute("data-layer-list-type"),
				index: toggle.getAttribute("data-layer-index")
			}
		});
	}

	public static pushDataLayerEvent_Lists_ViewItemList(toggle: Element): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "view_item_list",
			ecommerce: {
				item_list_id: toggle.getAttribute("data-layer-list-id"),
				item_list_name: toggle.getAttribute("data-layer-list-name"),
				list_type: toggle.getAttribute("data-layer-list-type")
			}
		});
	}

	// CFAC-34724 Conversions
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/conversions/conversions.md
	public static pushDataLayerEvent_Conversion(conversion: string): void {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		dataLayer.push({
			event: conversion
		});
	}

	public static pushDataLayerEvent_ClickData(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "click",
			eventModel: {
				category: toggle.getAttribute("data-layer-category"),
				link_classes: toggle.className,
				link_id: toggle.getAttribute("data-layer-id"),
				link_text: toggle.textContent,
				link_url: toggle.getAttribute("href"),
				protocol: toggle.getAttribute("data-layer-protocol")
			}
		});
	}

	/*
	CFAC-34729 Subscriptions
	https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/subscriptions
	*/
	public static pushDataLayerEvent_Form_Subscrible(
		event: string,
		eventModel: object
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: event,
			eventModel: eventModel
		});
	}

	// CFAC-34734 iframe_message
	// Fire whenever an iframe emits a message using the window.postMessage method.
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/iframe_message.md
	public static pushDataLayerEvent_IFrameMessage(
		toggle: HTMLElement,
		category: string
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "iframe_post_message",
			eventModel: {
				category: category || "",
				data: toggle.dataset.layerData,
				identifier: toggle.dataset.layerIdentifier,
				name: toggle.dataset.layerName,
				page_location: toggle.dataset.layerPageLocation
			}
		});
	}

	// CFAC-34730 Tabs
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/tabs/select_tab.md
	public static pushDataLayerEvent_Tabs_SelectTab(eventModel: object): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "select_tab",
			eventModel: eventModel
		});
	}

	// CFAC-34737 Share
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/share.md
	public static pushDataLayerEvent_Share(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "share",
			eventModel: {
				content_type: toggle.dataset.layerContentType,
				item_id: toggle.dataset.itemId,
				method: toggle.dataset.method
			}
		});
	}

	// CFAC-34727 Promotions
	// https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events/promotions
	public static pushDataLayerEvent_Promotion(
		event: string,
		eventModel: object
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
		window.dataLayer.push({
			event: event,
			ecommerce: eventModel
		});
	}

	// CFAC-34731 Video
	// https://github.com/blake-sdi/client-cfa-dl-spec/tree/cfa-ecomm-update/events/video
	public static pushDataLayerEvent_Video_Start(eventModel: object): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "video_start",
			eventModel: eventModel
		});
	}

	// CFAC-34764 login
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/authentication/login.md
	public static pushDataLayerEvent_Login(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({ userModel: null }); // Clear the previous eventModel object.
		dataLayer.push({
			event: "login",
			userModel: {
				authentication_method: "sign-in-button",
				user_id: toggle.dataset.userId,
				user_login_state: "authenticated"
			}
		});
	}

	// CFAC-34765 logout
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/authentication/logout.md
	public static pushDataLayerEvent_Logout(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({ userModel: null }); // Clear the previous eventModel object.
		dataLayer.push({
			event: "logout",
			userModel: {
				user_id: toggle.dataset.userId,
				user_login_state: "authenticated"
			}
		});
	}

	// CFAC-38373 scroll_milestone
	// https://github.com/blake-sdi/client-cfa-dl-spec/blob/cfa-ecomm-update/events/scroll_milestone.md
	public static pushDataLayerEvent_ScrollMilestone(milestone: string): void {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		dataLayer.push({
			event: "scroll_milestone",
			eventModel: {
				milestone: milestone
			}
		});
	}

	// CFAC-42476
	public static pushDataLayerEvent_PositionSearchClick(
		toggle: HTMLElement
	): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ event_data: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: toggle.dataset.eventName,
			event_data: {
				link_text: toggle.textContent,
				link_url: toggle.getAttribute("href"),
				location: window.location.href
			}
		});
	}

	// CFAC-42477 Localization Data Layers
	public static pushDataLayerEvent_NavTab_Click(eventModel: object): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ eventModel: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "nav_tab_click",
			eventModel: eventModel
		});
	}

	// CFAC-42477 Localization Data Layers
	public static pushDataLayerEvent_OrderClick(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ event_data: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: toggle.dataset.eventName,
			event_data: {
				link_text: toggle.textContent,
				link_url: toggle.getAttribute("href"),
				location: window.location.href
			}
		});
	}

	// CFAC-42477 Localization Data Layers
	public static pushDataLayerEvent_MatterPortClick(toggle: HTMLElement): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ event_data: null }); // Clear the previous eventModel object.
		window.dataLayer.push({
			event: "location_page_matterport_click",
			event_data: {
				location: window.location.href
			}
		});
	}

	public static pushDataLayerEvent_DaypartHomepageLoaded(data: object): void {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ data: null });
		window.dataLayer.push({
			event: "homepage_hero_load",
			data: data
		});
	}
}

ComponentFactory.registerComponent("DataLayerService", DataLayerService);
